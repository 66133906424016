import React, {useContext, useState} from "react";
import {toast} from "react-toastify";

import {IsLoggedIn} from "../../../utils/RolesChecker";

import {ChatbotContext} from "../Chatbot";
import {ChatbotService} from "../../../services/ChatbotService";
import {forEach} from "lodash";

const ChatbotFooter = () => {

	const IsLoggedInConst = IsLoggedIn();
	const contextValue = useContext(ChatbotContext);

	const [rgpdAccepted, setRgpdAccepted] = useState(false);
	const [showErrorMessages, setShowErrorMessages] = useState(false);
	const [clicked, setClicked] = useState(false);

	const handleRgpdChange = () => {
		setRgpdAccepted(!rgpdAccepted);
	};

	const previousQuestion = () => {
		let actualQuestion = contextValue.allQuestionsAnswered.find((item) => item.id === contextValue.activeQuestion.id);
		let index = contextValue.allQuestionsAnswered.indexOf(actualQuestion);
		let previousQuestion = contextValue.allQuestionsAnswered[index - 1];

		if (previousQuestion && previousQuestion.id) {
			contextValue.setActiveQuestion(previousQuestion.data);
			let newAllQuestionsAnswered = contextValue.allQuestionsAnswered.slice(0, index);
			let newAllQuestionsAnsweredWithNullAnswer = newAllQuestionsAnswered.map((item) => {
				if (item.id === previousQuestion.id) {
					item.answer = null;
					item.data.chatbotAnswers.forEach(element => {
						if (element.action === "login") {
							contextValue.setUserToken(null);
						}
					});
				}
				return item;
			});
			contextValue.setAllQuestionsAnswered(newAllQuestionsAnsweredWithNullAnswer);
		}
	}

	const submitChatbot = async () => {
		setClicked(true);
	
		// Check if the user accept the rgpd checkbox
		if (!rgpdAccepted) {
			setShowErrorMessages(true);
			setClicked(false);
			return;
		}
	
		// Ajouter un message d'avertissement lors de la tentative de fermeture ou d'actualisation de la page
		const handleBeforeUnload = (event) => {
			event.preventDefault();
			event.returnValue = ''; // Nécessaire pour certains navigateurs
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		toast.info("Veuillez patienter votre demande est en cours, ne quittez pas la page, vous serez automatiquement redirigé à la fin de l'envoi", {
			autoClose: 600000,
		});
	
		let id = contextValue.allQuestionsAnswered[0].data.chatbotProjectId;
		let token = contextValue.userToken;
	
		try {
			let request = await ChatbotService.validChatbot(id, {}, token);
	
			if (request.status === 200) {
				let files = contextValue.allQuestionsAnswered.filter((item) => item.answer.type === "file");
				let filesArray = [];
	
				// Ajout des fichiers dans filesArray
				for (let i = 0; i < files.length; i++) {
					const element = files[i].answer.answers;
					if (Array.isArray(element)) {
						filesArray.push(...element);
					}
	
					// Si c'est le dernier fichier et qu'il y a des fichiers à uploader
					if (filesArray.length > 0 && i === files.length - 1) {
						await submitFiles(filesArray, request.data.client.userToken, request.data.id);
						window.location.href = "https://monbureaudetudes.com/chatbot-devis-valide?completed=true";
					}
				}
	
				// Si aucun fichier à uploader
				if (filesArray.length === 0) {
					window.location.href = "https://monbureaudetudes.com/chatbot-devis-valide?completed=true";
				}
			} else {
				setClicked(false);
				toast.error("Une erreur est survenue lors de la soumission du chatbot");
			}
		} catch (error) {
			setClicked(false);
			toast.error("Une erreur est survenue lors de la soumission du chatbot");
		} finally {
			// Retirer l'événement beforeunload après la requête
			window.removeEventListener('beforeunload', handleBeforeUnload);
		}
	};

	const submitFiles = async (files, userToken, projectId) => {
		let filesUploaded = [];
		let filesNotUploaded = [];
		for (let i = 0; i < files.length; i++) {
			const element = files[i];
			let request = await ChatbotService.postMedias(userToken, element, projectId);
			if (request.status === 201) {
				filesUploaded.push(element);
			} else {
				filesNotUploaded.push(element);
			}
		}
		if (filesUploaded.length === files.length) {
			toast.success("Les fichiers ont bien été uploadés");
		}
		if (filesNotUploaded.length > 0) {
			forEach(filesNotUploaded, (file) => {
				toast.error(`Le fichier ${file.name} n'a pas pu être uploadé`);
			})
		}
	}

	return (
		<div className="mbe-chatbot__btns">
			{
				contextValue.activeQuestion ? (
					<button className="btn-default btn-default-blue left" onClick={() => {
						previousQuestion();
					}}>
						Question précédente
					</button>
				) : (
					<>
						<div className={"margin-bottom-25"}>
							<div className={"align-items"}>
								<input
									type="checkbox"
									id="rgpdCheckbox"
									name="rgpd"
									required
									onChange={handleRgpdChange}
								/>

								<label htmlFor="rgpdCheckbox" className={"text-primary color"}>J'accepte la transmission de mes
									informations à monbureaudetudes.com</label>

							</div>

							{
								showErrorMessages && !rgpdAccepted ? (
									<p className="error-message color-error text-primary margin-top-10">Merci d'accepter la transmission
										de vos informations à monbureaudetudes.com pour que nous puissions les traiter.</p>
								) : null
							}
						</div>

						{
							!clicked ? (
								<button className="btn-default btn-default-green center" onClick={() => submitChatbot()}>
									Valider les informations remplies
								</button>
							) : null
						}

					</>
				)
			}
		</div>
	)
}

export default ChatbotFooter;
